import { useState, useEffect } from 'react'
import { API } from 'aws-amplify';
import TipDetailDataTable from './TipDetailDataTable';
import TipDetailDataBarChart from './TipDetailDataBarChart';
import ScatterPlotUnitProfit from './ScatterPlotUnitProfit';
import ScatterPlotDiscountPercent from './ScatterPlotDiscountPercent';
import ScatterPlotLargeOrder from './ScatterPlotLargeOrder'
import LineChartLeadTimePrediction from './LineChartLeadTimePrediction';

const fetchTipDetailSupportingData = async (tipDetailID) => {

    const apiName = "dataTipsterApi";
    const path = "tip-detail-supporting-data";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        queryStringParameters: {
            tipDetailID: tipDetailID
        },
    };
    return API.get(apiName, path, myInit);
}


const TipDetailSupportingData = ({ oneTipDetailsID, size }) => {
    const [tipDetailSupportingData, setTipDetailSupportingData] = useState([])

    useEffect(() => {
        const getTipDetailSupportingData = async () => {
            const tipDetailSupportingDataFromServer = await fetchTipDetailSupportingData(oneTipDetailsID)
            setTipDetailSupportingData(tipDetailSupportingDataFromServer)
        }
        getTipDetailSupportingData()
    }, [oneTipDetailsID])

    return (

        <div key={oneTipDetailsID} className={(size==="small") ? "tipDetailDivSmall" : "tipDetailDiv"}>
            {
                tipDetailSupportingData.map(oneSupportingDetail => {

                    if (oneSupportingDetail.supporting_data_visual_hints === "table" & size !== "small") {
                        return (
                            <TipDetailDataTable key={oneSupportingDetail.id} supporting_data={oneSupportingDetail} />
                        )
                    }
                    else if (oneSupportingDetail.supporting_data_visual_hints === "bar-chart") {
                        return (
                            <TipDetailDataBarChart key={oneSupportingDetail.id} supporting_data={oneSupportingDetail} size={size} />
                        )
                    }
                    else if (oneSupportingDetail.supporting_data_visual_hints === "unit_profit_scatter") {
                        return (
                            <ScatterPlotUnitProfit key={oneSupportingDetail.id} supporting_data={oneSupportingDetail} size={size}/>
                        )
                    }
                    else if (oneSupportingDetail.supporting_data_visual_hints === "discount_percent_scatter") {
                        return (
                            <ScatterPlotDiscountPercent key={oneSupportingDetail.id} supporting_data={oneSupportingDetail} size={size}/>
                        )
                    }
                    else if (oneSupportingDetail.supporting_data_visual_hints === "large_invoice_scatter") {
                        return (
                            <ScatterPlotLargeOrder key={oneSupportingDetail.id} supporting_data={oneSupportingDetail} size={size}/>
                        )
                    }
                    else if (oneSupportingDetail.supporting_data_visual_hints === "lead-time-line-chart"  & size !== "small") {
                        return (
                            <LineChartLeadTimePrediction key={oneSupportingDetail.id} supporting_data={oneSupportingDetail} size={size}/>
                        )
                    }
                }
                )
            }

        </div>
    )
}


export default TipDetailSupportingData

