import Tip from './Tip'
import { ListGroup, Nav } from 'react-bootstrap'
import { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import React from 'react';

const TipsTimelineView = ({ tips, refreshTipHeaders }) => {
    const [shownTipStatuses, setShownTipStatueses] = useState([1]);
    const status_counts = [0,0,0,0,0,0];


    function useQuery() {
        const { search } = useLocation();
    
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery()
    let tipAttributeType = query.get("tipAttributeType")
    let tipAttributeValue = query.get("tipAttributeValue")
    
    useEffect(() => {
        refreshTipHeaders(tipAttributeType,tipAttributeValue)
    }, [tipAttributeType,tipAttributeValue])

    for (const tip of tips) {
        status_counts[tip.status_id] = status_counts[tip.status_id] ? status_counts[tip.status_id] + 1 : 1;
    }
    return (

        <div className="tipHeaders">
            <div className="tipTimelineHeader">
                <h4>Recent Activity</h4>
            <Nav variant="pills" defaultActiveKey="1">
                <Nav.Item >
                    <Nav.Link eventKey="1,2,3,4,5" onClick={() => setShownTipStatueses([1, 2, 3, 4, 5])}>All ({status_counts[1] + status_counts[2] + status_counts[3] + status_counts[4] + status_counts[5]}) </Nav.Link>
                </Nav.Item>
                <Nav.Item >
                    <Nav.Link eventKey="1" onClick={() => setShownTipStatueses([1])}>My Responsibilities ({status_counts[1]})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="5" onClick={() => setShownTipStatueses([5])}>On Hold ({status_counts[5]})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="2,3,4" onClick={() => setShownTipStatueses([2, 3, 4])} >Closed</Nav.Link>
                </Nav.Item>
            </Nav>
            </div>
            <div className="tipsTimeline">
                <ListGroup>
                {
                    tips.map(oneTip => {
                        return (
                            (shownTipStatuses.includes(parseInt(oneTip.status_id)) === true) && <Tip key={oneTip.tip_header_id} tipDetails={oneTip} />

                        )
                    }
                    )
                }
                </ListGroup>
            </div>

        </div>

    )
}

export default TipsTimelineView
