const Settings = () => {

    return (
        <div className="settingsDiv">
            <h4>This is where Settings Go</h4>
        </div>
    )
}

export default Settings

