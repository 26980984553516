import Table from 'react-bootstrap/Table';
import moment from 'moment';

const TipDetailDataTable = ({ supporting_data }) => {
    const parsedSupportingData = JSON.parse(supporting_data.supporting_data)
    return (
        <div className="tipDetailTableDiv">
            <hr />
            <h5 className="tipDetailTableHeader">{supporting_data.supporting_data_header}</h5>
            <Table striped bordered hover size="sm" responsive className="tipDetailTable">
                <thead>
                    <tr>
                        {Object.keys(parsedSupportingData[0]).map(heading => {
                            return (
                                <td>{heading}</td>
                            )
                        })}

                    </tr>
                </thead>
                <tbody>
                    {parsedSupportingData.map(row => {
                        return (<tr>
                            {Object.keys(row).map(cellID => {
                                return (
                                    <td>{(moment(row[cellID],["YYYY-MM-DDTHH:mm:ss.SSSZ","MM/D/YYYY hh:mm:ss a"], true).isValid()) ? moment.utc(row[cellID]).format('YYYY-MM-DD'):  row[cellID]}</td>
                                )
                            }
                            )
                            }
                        </tr>)
                    })}
                </tbody>
            </Table>
        </div>

    )
}

export default TipDetailDataTable

