import { Auth } from 'aws-amplify';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import { List, Settings, LogOut, Activity, Upload, Grid } from 'react-feather'
import { Link } from 'react-router-dom'

async function signOut() {
  try {
    await Auth.signOut();
    window.location.reload(false);
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const SideBar = ({uploader}) => {

  return (
    <div className="sidebar">
      <ProSidebar breakPoint="sm">
        <SidebarHeader>
          <img
            alt="logo"
            src="/horizontal-logo-white-noTag.png"
            height="43pt"
            className="d-inline-block align-top"
          />
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem icon={<List />} >Timeline<Link to="/" /></MenuItem>
            <MenuItem icon={<Grid />} >Grid View<Link to="/Grid" /></MenuItem>
            <MenuItem icon={<Activity />} >Overview<Link to="/Activities" /></MenuItem>
            <MenuItem icon={<Settings />}>Settings <Link to="/Settings" /></MenuItem>
            {(uploader) ? <MenuItem icon={<Upload />}>Upload <Link to="/Upload" /></MenuItem>: ''}
            <MenuItem icon={<LogOut />} onClick={signOut}>Logout</MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  )
}


export default SideBar
