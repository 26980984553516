import SideBar from './components/SideBar'
import TipsTimelineView from './components/TipsTimelineView'
import TipsGridView from './components/TipsGridView'
import TipDetails from './components/TipDetails'
import Settings from './components/Settings'
import Activities from './components/Activities'
import Uploader from './components/Uploader'
import AttributeSummary from './components/AttributeSummary'

import { useState, useEffect } from 'react'

import { Amplify, Auth, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Container, Row, Col } from 'react-bootstrap'

import './styles/App.css'
import './styles/custom.scss'
import '@aws-amplify/ui-react/styles.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import React from 'react'


Amplify.configure({
  Auth: {
    //REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:009e326d-4859-4766-ba89-0cb7be2f4ffc',
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_hj4pG5Kpw',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '7jhhfivrpnu0ug3kvan8ugfa35',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

  },
  Storage: {
    AWSS3: {
      bucket: 'dt-ingestor', //REQUIRED -  Amazon S3 bucket name
      region: 'us-west-2', //OPTIONAL -  Amazon service region
    }
  },
  API: {
    endpoints: [
      {
        name: "dataTipsterApi",
        endpoint: "https://k4jyoi9vde.execute-api.us-west-2.amazonaws.com/test/",
        custom_header: async () => {
          // return { Authorization : 'token' } 
          // Alternatively, with Cognito User Pools use this:
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          //return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
});

const fetchTipHeaders = async (tipAttributeType, tipAttributeValue) => {

  const apiName = "dataTipsterApi";
  const path = "tip-headers";
  const myInit = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    queryStringParameters: {  // OPTIONAL
      tipAttributeType: tipAttributeType,
      tipAttributeValue:tipAttributeValue
  },
  };
  return API.get(apiName, path, myInit);
}



function App() {

  const [tips, setTips] = useState([])
  const [userAuthInfo, setUserAuthInfo] = useState([])
  const [uploader, setUploader] = useState(false)

  const getUserAuthInfo = async () => {
    const userInfoFromServer = await Auth.currentAuthenticatedUser()
    setUploader(false)
    const groups = userInfoFromServer.signInUserSession.accessToken.payload["cognito:groups"];
    if (groups) {
      const uploader = groups.includes('uploaders');
      setUploader(uploader)
    }
    setUserAuthInfo(userInfoFromServer)
  }
  const refreshTipHeaders = async (tipAttributeType, tipAttributeValue) => {

    const tipHeadersFromServer = await fetchTipHeaders(tipAttributeType, tipAttributeValue)
    setTips(tipHeadersFromServer)

  }

  useEffect(() => {
    getUserAuthInfo()
  }, [])

  return (
    <div className="App">

      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous"
      />

      <Router>
        <SideBar uploader={uploader} />

        <Routes>

          <Route path="/Settings" element={<Settings />} />
          <Route path="/Activities" element={<Activities tipHeaderData={tips} />} ></Route>
          <Route path="/Grid" element={<TipsGridView tipHeaderData={tips} refreshTipHeaders={refreshTipHeaders} />} ></Route>
          <Route path="/Upload" element={<Uploader tipHeaderData={tips} />} ></Route>
          <Route path="/" element={<Container fluid>
            <Row>
              <Col sm={12} md={9} lg={9} >
                <TipsTimelineView tips={tips} refreshTipHeaders={refreshTipHeaders}  />
              </Col>
            </Row>
          </Container>} />

          <Route path="/Tip">
            <Route path=":tipID" element={<TipDetails refreshTipHeaders={refreshTipHeaders} />} />
          </Route>
          <Route path="/AttributeSummary" element={<AttributeSummary />} > </Route>
        </Routes>
      </Router>

    </div>
  );
}

function GridWrapper() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery()
  return (
    <h1>Barf {query.get("attributeType")} {query.get("attributeValue")}</h1>
  )
}

export default withAuthenticator(App);
