import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

const ScatterPlotDiscountPercent = ({ supporting_data, size }) => {
    const parsedSupportingData = JSON.parse(supporting_data.supporting_data)
    
    const data_core = parsedSupportingData[0]['data_core']
    const data_outliers = [parsedSupportingData[0]['data_outliers']]

    return (
        
        <div className={(size !=="small") ? "tipDetailChartDiv": "tipDetailChartDivSmall"}>
            {(size !=="small") && <hr/> }
            {(size !=="small") && <h5 className="tipDetailTableHeader">{supporting_data.supporting_data_header}</h5>}
            <ResponsiveContainer minwidth={(size !=="small") ? 300 : 135} height={(size !=="small") ? 300 : 135}>
                <ScatterChart margin={{ top: 20, right: 20, bottom: (size !=="small") ? 40 : 0, left: 20 }}>
                    <CartesianGrid vertical={false}/>
                    <YAxis type="number" dataKey="discount_pct" name="Discount Percent" unit="%" tick={{fontSize: (size !=="small") ? 14 : 12}}/>
                    <XAxis type="number" dataKey="invoiced_qty" name="Quantity Shipped" unit="qty" tick={{fontSize: (size !=="small") ? 14 : 12}}/>
                    <ZAxis type="string" dataKey="invoice_id" name="Invoice ID" />
                    <Scatter name="Sales History" data={data_core} fill="rgba(212, 157, 66, 0.6)" isAnimationActive={(size === "small") ? false : true} line={{stroke: 'red', strokeWidth: (size === "small") ? 0 : 1}} lineType="fitting"/>
                    <Scatter name="Outlier" data={data_outliers} fill="#693C72" animationDuration={2000} isAnimationActive={(size === "small") ? false : true} shape="cross"/>
                    {(size !=="small") && <Legend verticalAlign="top" /> }
                    {(size !=="small") && <Tooltip cursor={{ strokeDasharray: '3 3' }}/>}
                </ScatterChart>
            </ResponsiveContainer>
            
        </div>
        
    )
}

export default ScatterPlotDiscountPercent

