import { Card, Dropdown } from 'react-bootstrap';
import TipDetailSupportingData from './TipDetailSupportingData';
import { Clock } from 'react-feather';
import ReactMarkdown from 'react-markdown';

const TipDetail = ({ oneTipDetails, handleDeleteComment, tipHeaderID }) => {
    function LinkRenderer(props) {
        return <a href={props.href} target="_blank" rel="noreferrer">{props.children}</a>
      }
    

    return (

        <div key={oneTipDetails.id} className="tipDetailDiv">
            <Card className="tipDetailsCard">

                <Card.Body >

                    <Card.Text className="tipDetailText">
                        <ReactMarkdown components={{a: LinkRenderer}}>{oneTipDetails.message}</ReactMarkdown>
                    </Card.Text>

                    <TipDetailSupportingData oneTipDetailsID={oneTipDetails.id}/>

                </Card.Body>
                <Card.Footer className="tipDetailFooter">
                    <span className="tipDetailFooterMessage">{oneTipDetails.id}: {oneTipDetails.type} by <em>{oneTipDetails.first_name} {oneTipDetails.last_name}</em></span>
                    <span className="tipDetailHeaderGeneratedAt">
                        {oneTipDetails.type === "user_comment" &&
                            <Dropdown variant="link" title="test" >
                                <Dropdown.Toggle variant="link" id="dropdown-basic" className="tipDetailFooterGeneratedAt">
                                    <Clock size={18} /> {oneTipDetails.created_dt}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleDeleteComment(oneTipDetails.id, tipHeaderID)}>Delete Comment</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        
                        {oneTipDetails.type !== "user_comment" &&
                            <span className="tipDetailFooterGeneratedAt"><Clock size={18} > </Clock> {oneTipDetails.created_dt} </span>    
                            }

                    </span>
                </Card.Footer>
            </Card>
        </div>


    )
}

export default TipDetail

