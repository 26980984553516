
import { MessageCircle, User, Coffee, Clock } from 'react-feather';
import Button from 'react-bootstrap/Button'
import { ButtonGroup, ListGroup } from 'react-bootstrap';
import TipDetailSupportingData from './TipDetailSupportingData';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom'

const Tip = ({ tipDetails }) => {
    function LinkRenderer(props) {
        return <a href={props.href} target="_blank" rel="noreferrer">{props.children}</a>
    }


    return (
        <>
        <Link to={`/Tip/${tipDetails.tip_header_id}`} style={{ textDecoration: 'none' }}>
            <ListGroup.Item as="div" action className="tipsDiv">
                <h4 className="tipTitle">{tipDetails.tip_type} for {tipDetails.source_id}</h4>
                <ReactMarkdown className="tipHeaderMessage" components={{ a: LinkRenderer }}>{tipDetails.tip_most_recent_message}</ReactMarkdown>
                {(tipDetails.tip_detail_type === "generated_alert") && <TipDetailSupportingData oneTipDetailsID={tipDetails.tip_last_detail_id} size="small" />}

                <ButtonGroup size="sm">
                    <Button variant="link"><MessageCircle size={20} />{tipDetails.details_count}</Button>
                    <Button variant="link"><User size={20} />{tipDetails.assigned_user_first_name} {tipDetails.assigned_user_last_name}</Button>
                </ButtonGroup>
                <span className="tipHeaderGeneratedAt"> <Coffee size={20} /> Status: {tipDetails.tip_status_description} <Clock size={18} /> {tipDetails.tip_most_recent_activity}</span>

            </ListGroup.Item>
        </Link>
        </>
    )
}

export default Tip

