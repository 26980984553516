import { Line, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, CartesianGrid } from 'recharts';
import moment from 'moment'

const TipDetailDataBarChart = ({ supporting_data, size }) => {
    const parsedSupportingData = JSON.parse(supporting_data.supporting_data)

    const dateFormatter = date => {
        return moment.utc(date).format("MMM-DD");
    };

    return (
        <div className={(size !== "small") ? "tipDetailChartDiv": "tipDetailChartDivSmall"}>
            {(size !== "small") && <hr/> }
            {(size !== "small") && <h5 className="tipDetailTableHeader">{supporting_data.supporting_data_header}</h5>}
            <ResponsiveContainer minwidth={(size !== "small") ? 300 : 150} height={(size !== "small") ? 300 : 150}>
                <ComposedChart data={parsedSupportingData} margin={{ top: 20, right: 20, bottom: 22, left: 0 }}>
                    <CartesianGrid vertical={false}/>
                    <Bar isAnimationActive={(size === "small") ? false : true} dataKey="qty_in" fill="#D49D42" />
                    <Bar isAnimationActive={(size === "small") ? false : true} dataKey="qty_out" fill="#693C72" />
                    <Line isAnimationActive={(size === "small") ? false : true} dataKey="inv" stroke="#C15050" strokeWidth={2} type="monotone" />
                    <XAxis dataKey="dt" tickFormatter={dateFormatter} angle={45}
                        dx={15}
                        dy={20}
                        minTickGap={-200}
                        axisLine={false} 
                        tick={{fontSize: (size !== "small") ? 14 : 12}} />
                    <YAxis 
                        tick={{fontSize: (size !== "small") ? 14 : 12}} />
                    {(size !== "small") && <Legend verticalAlign="top" />}
                    {(size !== "small") && <Tooltip labelFormatter={(dt) => 'Date: '+moment.utc(dt).format("YYYY-MM-DD")}/>}
                </ComposedChart>
            </ResponsiveContainer>
        </div>

    )
}

export default TipDetailDataBarChart

