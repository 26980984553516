import { Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, CartesianGrid } from 'recharts';
import moment from 'moment';

const LineChartLeadTimePrediction = ({ supporting_data, size }) => {
    const parsedSupportingData = JSON.parse(supporting_data.supporting_data)

    const dateFormatter = date => {
        return moment.utc(date).format("MMM-DD");
    };

    return (
        <div className={(size !== "small") ? "tipDetailChartDiv": "tipDetailChartDivSmall"}>
            {(size !== "small") && <hr/> }
            {(size !== "small") && <h5 className="tipDetailTableHeader">{supporting_data.supporting_data_header}</h5>}
            <ResponsiveContainer minwidth={(size !== "small") ? 300 : 150} height={(size !== "small") ? 350 : 150}>
                <ComposedChart data={parsedSupportingData} margin={{ top: 20, right: 20, bottom: 22, left: 0 }}>
                    <CartesianGrid vertical={false}/>

                    <Line isAnimationActive={(size === "small") ? false : true} dataKey="average_lead_time" stroke="#D49D42" strokeWidth={2} type="monotone" />
                    <Line isAnimationActive={(size === "small") ? false : true} dataKey="predicted_lead_time" stroke="#693C72" strokeWidth={2} type="monotone" />
                    
                    <XAxis dataKey="month" tickFormatter={dateFormatter} angle={45}
                        dx={15}
                        dy={20}
                        minTickGap={-200}
                        axisLine={false} 
                        tick={{fontSize: (size !== "small") ? 14 : 12}} />
                    <YAxis 
                        tick={{fontSize: (size !== "small") ? 14 : 12}} />
                    {(size !== "small") && <Legend verticalAlign="top" />}
                    {(size !== "small") && <Tooltip labelFormatter={(month) => 'Month: '+moment(month).format("MMM-YYYY")}/>}
                </ComposedChart>
            </ResponsiveContainer>
        </div>

    )
}

export default LineChartLeadTimePrediction

