import * as React from 'react';
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Card, Form, Container, Row, Col } from 'react-bootstrap';

import { ArrowDown } from 'react-feather';
import moment from 'moment';
import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'

const fetchTipAttributeSummary = async () => {

    const apiName = "dataTipsterApi";
    const path = "tip-attributes";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },

    };
    return API.get(apiName, path, myInit);
}

const dateFormatter = (date) => {

    var testDateUtc = moment.utc(date);
    var localDate = moment(testDateUtc).local();
    var s = localDate.format("MMM-DD hh:mm A");
    return (s)
}

const columns = [
    { id: 'type', name: 'type', grow: 1, selector: row => <Link to={`/?tipAttributeType=${row.type}`}>{row.type}</Link>, },
    { id: 'value', name: 'value', grow: 3, selector: row => <Link to={`/?tipAttributeType=${row.type}&tipAttributeValue=${row.value}`}>{row.value}</Link>, },
    {
        id: 'most_recent_tip_dt',
        format: row => dateFormatter(row.most_recent_tip_dt),
        grow: 2,
        name: 'Recent Update',
        sortable: true,
        selector: row => row.most_recent_tip_dt,
    },
    { name: 'last_7_day_tips', grow: 1, sortable: true, selector: row => row.last_7_day_tips, },
    { name: 'tip_count_total', grow: 1, sortable: true, selector: row => row.tip_count_total },
];



const TipsGridView = ({ }) => {
    const [tipAttributeSummary, setTipAttributeSummary] = useState([])
    const [tipAttributeSummaryFiltered, setTipAttributeSummaryFiltered] = useState([])

    const [tipAttributeTypes, setTipAttributeTypes] = useState([])
    const [selectedAttributeType, setSelectedAttributeType] = useState([])
    const [selectedAttributeValue, setSelectedAttributeValue] = useState([])

    const getAttributeSummary = async () => {
        const tipAttributeSummaryFromServer = await fetchTipAttributeSummary()
        const uniqueTypes = [...new Set(tipAttributeSummaryFromServer.map(item => item.type))];
        setTipAttributeTypes(uniqueTypes)
        setTipAttributeSummary(tipAttributeSummaryFromServer)
        setTipAttributeSummaryFiltered(tipAttributeSummaryFromServer)
    }

    useEffect(() => {
        getAttributeSummary()
    }, [])
    function handleTypeChange(event) {
        setSelectedAttributeType(event.target.value)
        setSelectedAttributeValue('')
        if (event.target.value != '') {
            setTipAttributeSummaryFiltered(tipAttributeSummary.filter(row => row.type == event.target.value))
        } else {
            setTipAttributeSummaryFiltered(tipAttributeSummary)
        }
    }
    function handleValueChange(event) {
        setSelectedAttributeValue(event.target.value)
        if (event.target.value != '') {
            setTipAttributeSummaryFiltered(tipAttributeSummary.filter(row => (row.type == selectedAttributeType) && (row.value == event.target.value)))
        } else {
            setTipAttributeSummaryFiltered(tipAttributeSummary.filter(row => (row.type == selectedAttributeType)))
        }
    }
    return (
        <div className="tipsGridView">

            <h4>Attribute Summary {selectedAttributeType} {selectedAttributeValue}</h4>
            <Container>
                <Row>
                    <Col>
                        <Form>
                            <Form.Label>Type</Form.Label>
                            <Form.Select onChange={handleTypeChange}>
                                <option value=""></option>
                                {tipAttributeTypes.map(oneAttribute => {
                                    return (
                                        <option value={oneAttribute}>{oneAttribute}</option>
                                    )
                                }
                                )
                                }
                            </Form.Select>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <Form.Label>Value</Form.Label>
                            <Form.Select onChange={handleValueChange} value={selectedAttributeValue}>
                                <option value=""></option>
                                {tipAttributeSummary.filter(row => row.type == selectedAttributeType).map(oneAttribute => {
                                    return (
                                        <option value={oneAttribute.value}>{oneAttribute.value}</option>
                                    )
                                }
                                )
                                }
                            </Form.Select>
                        </Form>
                    </Col>
                </Row>
            </Container>


            <hr />
            <Card>
                <DataTable
                    columns={columns}
                    data={tipAttributeSummaryFiltered}
                    title='Tip Attributes'
                    keyField='value'
                    responsive={true}
                    dense
                    paginationresponsive
                    striped
                    defaultSortFieldId={"most_recent_tip_dt"}
                    defaultSortAsc={false}
                    sortIcon={<ArrowDown />}
                    fixedHeader
                    fixedHeaderScrollHeight="85vh"
                />
            </Card>
        </div>
    )
}

export default TipsGridView

