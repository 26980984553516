import { Storage } from "aws-amplify";
import { useState } from 'react'
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form'

const Uploader = () => {

    const [uploadFile, setUploadFile] = useState()
    const [uploadLog, setUploadLog] = useState()

    async function onChange(e) {

        try {
            const file = e.target.files[0]
            if (file.name === "dt-data.zip") {
                await Storage.put(file.name, file, {
                    completeCallback: (event) => {
                        setUploadLog(`Successfully uploaded ${event.key}`);
                    },
                    progressCallback(progress) {
                        setUploadLog(`Uploaded: ${progress.loaded}/${progress.total}`);
                    },
                    level: "private",
                    resumable: true,
                });
            } else {
                setUploadLog("File must be named dt-data.zip")
            }
        } catch (error) {
            setUploadLog("Error uploading file: ", error);
        }
    }



    return (
        <div className="uploaderDiv">
            <h4>Upload Data</h4>
            <hr></hr>
            <div>
                <p>
                    Data Tipster expects a single file named <strong>dt-data.zip</strong> containing multiple csv files in the root directory:
                </p>
            </div>

            <Form>
                <Form.Group className="mb-3" controlId="formUploaderFile">
                    <Form.Label>File</Form.Label>
                    <Form.Control type="file" placeholder="zipped .csv files" onChange={setUploadFile} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formUploaderStatus">
                    <Form.Label>Status:</Form.Label>

                    <Form.Control as="textarea"
                        disabled
                        rows={2}
                        value={uploadLog} />
                </Form.Group>

                <Button variant="primary" onClick={() => onChange(uploadFile)}>
                    Upload
                </Button>

            </Form>


        </div>
    )
}

export default Uploader

