import { useState, useEffect } from 'react'
import { API } from 'aws-amplify';
import { Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, CartesianGrid } from 'recharts';

import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'
import MaterialTable from 'material-table'
import { Container, Row} from 'react-bootstrap'

const fetchTipActivity = async () => {

    const apiName = "dataTipsterApi";
    const path = "tip-activities";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },

    };
    return API.get(apiName, path, myInit);
}

const Activities = ({ tipHeaderData }) => {
    const [tipActivityData, setTipActivityData] = useState([])

    useEffect(() => {
        const getTipActivityDataFromServer = async () => {
            const tipActivityDataFromServer = await fetchTipActivity()
            setTipActivityData(tipActivityDataFromServer)
        }
        getTipActivityDataFromServer()
    }, [])

    return (
        <div className="tipActivities">
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <Container >
            <Row>
            <h4>Overview</h4>
            </Row>
            <Row>
            <Card>
                <Card.Body>
                    <Card.Title>Actvities by User</Card.Title>
                    <ResponsiveContainer height={300} minwidth={600}>
                        <ComposedChart data={tipActivityData} margin={{ top: 20, right: 20, bottom: 40, left: 0 }}>
                            <CartesianGrid vertical={false} />
                            <Bar dataKey="Gary Owen" fill="#D49D42" stackId="a" />
                            <Bar dataKey="System Main" fill="#693C72" stackId="a" />
                            <Bar dataKey="Bill Tipster" fill="#C15050" stackId="a" />

                            <XAxis dataKey="created_date" angle={0}
                                dx={15}
                                dy={20}
                                minTickGap={-200}
                                axisLine={false}
                                tick={{ fontSize: 14 }} />
                            <YAxis
                                tick={{ fontSize: 14 }} />
                            <Legend verticalAlign="top" />
                            <Tooltip />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Card.Body>
            </Card>
            </Row>
            <Row>
                <MaterialTable
                    columns={[
                        { title: 'ID', field: 'tip_header_id', render: rowData => <Link to={`/Tip/${rowData.tip_header_id}`}>{rowData.tip_header_id}</Link> },
                        { title: 'Type', field: 'tip_type' },
                        { title: 'Source ID', field: 'source_id' },
                        { title: 'Assigned User ID', field: 'assigned_user_id' },
                        { title: 'First Name', field: 'assigned_user_first_name' },
                        { title: 'Last Name', field: 'assigned_user_last_name' },
                        { title: 'Status', field: 'tip_status_description' },
                        { title: 'Status Type', field: 'status_type' },
                        { title: 'Most Recent Activity', field: 'tip_most_recent_activity', type: 'datetime' },
                        { title: 'Recent Activity Type', field: 'tip_detail_type' },
                        { title: 'Details Count', field: 'details_count' },
                    ]}
                    data={tipHeaderData}
                    title="Recent Tips"
                    options={{
                        grouping: true,
                        padding: 'dense',
                        pageSize: 10,
                        doubleHorizontalScroll: true,
                        rowStyle: {
                            fontSize: "10pt",
                            whiteSpace: "nowrap",

                        }
                    }}
                />
            </Row>

            
            </Container>
        </div>
    )
}

export default Activities

