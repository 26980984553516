import { ButtonGroup, Button } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import TipDetail from './TipDetail';
import { Coffee, MessageCircle, ThumbsUp, ThumbsDown, User } from 'react-feather';
import AddComment from './AddComment';
import { useState, useEffect } from 'react'
import { API } from "aws-amplify"

const fetchTipDetails = async (tipHeaderID) => {

    const apiName = "dataTipsterApi";
    const path = "tip-details";
    const myInit = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      queryStringParameters: {  // OPTIONAL
        tipHeaderID: tipHeaderID
      },
    };
    return API.get(apiName, path, myInit);
  }

const fetchTipHeader = async (tipHeaderID) => {

    const apiName = "dataTipsterApi";
    const path = "tip-headers";
    const myInit = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      queryStringParameters: {  // OPTIONAL
        tipHeaderID: tipHeaderID
      },
    };
    return API.get(apiName, path, myInit);
  }

const postComment = async (tipHeaderID, CommentText) => {

    const apiName = "dataTipsterApi";
    const path = "tip-details";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: CommentText,
        queryStringParameters: {  // OPTIONAL
            tipHeaderID: tipHeaderID
        },
    };

    return API.post(apiName, path, myInit);
}
const putTipStatus = async (tipHeaderID, tipStatusID) => {

    const apiName = "dataTipsterApi";
    const path = "tip-headers";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },

        queryStringParameters: {  // OPTIONAL
            tipHeaderID: tipHeaderID,
            tipStatusID: tipStatusID
        },
    };

    return API.put(apiName, path, myInit);
}
const putTipAssignedUser = async (tipHeaderID, assignToUserID) => {

    const apiName = "dataTipsterApi";
    const path = "tip-headers";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },

        queryStringParameters: {  // OPTIONAL
            tipHeaderID: tipHeaderID,
            assignedUserID: assignToUserID
        },
    };

    return API.put(apiName, path, myInit);
}
const deleteTipComment = async (tipDetailID) => {

    const apiName = "dataTipsterApi";
    const path = "tip-details";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        queryStringParameters: {
            tipDetailID: tipDetailID
        },
    };
    return API.del(apiName, path, myInit);
}

const getAvailableUsers = async () => {
    const apiName = "dataTipsterApi";
    const path = "users";
    const myInit = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    return API.get(apiName, path, myInit);
}

const TipDetailsGridView = ({ refreshTipHeaders, tipID }) => {

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [tipDetails, setTipDetails] = useState([])
    const [tipHeader, setTipHeader] = useState([])
    const [tipHeaderID] = useState(tipID)
    
    const handleGetUsers = async () => {
        const result = await getAvailableUsers()
        setAvailableUsers(result)
    }

    const handleDeleteComment = async (tipDetailID, tipHeaderID) => {
        await deleteTipComment(tipDetailID)
        await handleGetTipDetails(tipHeaderID)
        refreshTipHeaders()
    }

    const handleAddComment = async (CommentText, tipHeaderID) => {
        if (CommentText) {
            await postComment(tipHeaderID, CommentText)
            await handleGetTipDetails(tipHeaderID)
            refreshTipHeaders()
        }
        setShowCommentBox(false);
    }

    const handlePutStatus = async (tipHeaderID, tipStatusID) => {
        await putTipStatus(tipHeaderID, tipStatusID)
        await handleGetTipHeader(tipHeaderID)
        await handleGetTipDetails(tipHeaderID)
        refreshTipHeaders()
    }

    const handlePutUser = async (tipHeaderID, assignToUserID) => {
        await putTipAssignedUser(tipHeaderID, assignToUserID)
        await handleGetTipHeader(tipHeaderID)
        await handleGetTipDetails(tipHeaderID)
        refreshTipHeaders()
    }

    const handleGetTipDetails = async (id) => {
        //Get The Tip Details from the API here, but they're already loaded
        const tipDetailsFromServer = await fetchTipDetails(id)
        setTipDetails(tipDetailsFromServer)
      }
    
      const handleGetTipHeader = async (id) => {
        //Get The Tip Details from the API here, but they're already loaded
        const tipHeaderFromServer = await fetchTipHeader(id)
        setTipHeader(tipHeaderFromServer)
    
      }
    const handleShow = () => setShowCommentBox(true);

    useEffect(() => {

        handleGetUsers()
        handleGetTipDetails(tipHeaderID)
        handleGetTipHeader(tipHeaderID)
    }, [tipHeaderID])

    const successfulPage = () => {
        return(

        <div className="tipDetails">
            
            <AddComment tipID={tipHeaderID} handleClose={handleAddComment} show={showCommentBox} />
                
            <h6>

                <ButtonGroup>
                    <Button variant="link" onClick={handleShow}><MessageCircle size={18} />Add Comment</Button>
                    <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <Coffee size={18} />Status: {tipHeader[0].tip_status_description}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handlePutStatus(tipHeaderID, 1)}>Open</Dropdown.Item>
                            <Dropdown.Item onClick={() => handlePutStatus(tipHeaderID, 2)}>Closed (Useful Tip)<ThumbsUp size={18} /></Dropdown.Item>
                            <Dropdown.Item onClick={() => handlePutStatus(tipHeaderID, 3)}>Closed (Bad Tip)<ThumbsDown size={18} /></Dropdown.Item>
                            <Dropdown.Item onClick={() => handlePutStatus(tipHeaderID, 4)}>Closed (Unknown)</Dropdown.Item>
                            <Dropdown.Item onClick={() => handlePutStatus(tipHeaderID, 5)}>On Hold</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <User size={18} />{tipHeader[0].assigned_user_first_name} {tipHeader[0].assigned_user_last_name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                availableUsers.map(user => {
                                    return (
                                        (tipHeader[0].assigned_user_id !== user.id) && <Dropdown.Item key={user.id} onClick={() => handlePutUser(tipHeaderID, user.id)}>{user.first_name} {user.last_name}</Dropdown.Item>
                                    )
                                }
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
            </h6>
            <div className="tipDetailsTimeline">
                {
                    tipDetails.map(oneTipDetails => {
                        return (
                            <TipDetail tipHeaderID={tipHeaderID} key={oneTipDetails.id} oneTipDetails={oneTipDetails} handleDeleteComment={handleDeleteComment}></TipDetail>
                        )
                    }
                    )
                }
            </div>

        </div>
        )
    }
 

    return (
        <>
        {tipHeader.length > 0 &&
            successfulPage()
        }
        </>
    )
}

export default TipDetailsGridView