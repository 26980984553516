import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import { useState } from 'react'

const AddComment = ({ tipID, handleClose, show }) => {
    const [commentText, setCommentText] = useState("")

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Comment to Tip: {tipID}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <FormControl
                            as="textarea"
                            rows={3}
                            maxLength={1024}
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {handleClose("", tipID); setCommentText("")}}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {handleClose(commentText, tipID); setCommentText("")}}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default AddComment
