import * as React from 'react';
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { ArrowDown } from 'react-feather';
import TipDetailsGridView from './TipDetailsGridView';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'

const dateFormatter = (date) => {
    
    var testDateUtc = moment.utc(date);
    var localDate = moment(testDateUtc).local();
    var s = localDate.format("MMM-DD hh:mm A");
    return (s)
}

const columns = [
    { id:'tip_header_id', width:'50pt', name: 'ID', grow: 1, sortable: true, selector: row => <Link to={`/Tip/${row.tip_header_id}`}>{row.tip_header_id}</Link>, },
    { id: 'tip_most_recent_activity', 
        format: row => dateFormatter(row.tip_most_recent_activity),
        grow:4, 
        name: 'Recent Update', 
        sortable: true, 
        selector: row => row.tip_most_recent_activity, },
    { name: 'Status', grow: 3, sortable: true, selector: row => row.tip_status_description, },
    { name: 'Assigned User', grow: 3, sortable:true, selector: row => row.assigned_user_name},
    { name: 'Type', grow:5, sortable: true, selector: row => row.tip_type, },
    { name: 'Source ID', grow: 10 ,sortable: true, selector: row => row.source_id, },
];



const TipsGridView = ({ tipHeaderData, refreshTipHeaders }) => {

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()
    let tipAttributeType = query.get("tipAttributeType")
    let tipAttributeValue = query.get("tipAttributeValue")
    
    function refreshTipHeadersWithQueryStrings(){
        refreshTipHeaders(tipAttributeType,tipAttributeValue)
    }    
    useEffect(() => {
        refreshTipHeadersWithQueryStrings()
    }, [tipAttributeType,tipAttributeValue])

    // data provides access to your row data
    const ExpandedComponent = ({ data }) => {
        return (<TipDetailsGridView tipID={data.tip_header_id} refreshTipHeaders={refreshTipHeadersWithQueryStrings} />);
    };

    return (
        <div className="tipsGridView">

            <h4>Overview {query.get("tipAttributeType")} {query.get("tipAttributeValue")}</h4>
            <Card>
                <DataTable
                    columns={columns}
                    data={tipHeaderData}
                    keyField='tip_header_id'
                    title='Tips'
                    responsive={true}
                    
                    paginationresponsive
                    striped
                    defaultSortFieldId={"tip_most_recent_activity"}
                    defaultSortAsc={false}
                    sortIcon={<ArrowDown />}
                    fixedHeader
                    fixedHeaderScrollHeight="85vh"
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
            </Card>
        </div>
    )
}

export default TipsGridView

