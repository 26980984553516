import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

const ScatterPlotLargeOrder = ({ supporting_data, size }) => {
    const parsedSupportingData = JSON.parse(supporting_data.supporting_data)

    const data_core = parsedSupportingData[0]['data_core']
    const data_outliers = parsedSupportingData[0]['data_outliers']

    const formatCurrency = (tickItem) => {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(tickItem);
    }

    return (

        <div className={(size !== "small") ? "tipDetailChartDiv" : "tipDetailChartDivSmall"}>
            {(size !== "small") && <hr />}
            <ResponsiveContainer minwidth={(size !== "small") ? 300 : 135} height={(size !== "small") ? 300 : 135}>
                <ScatterChart margin={{ top: 20, right: 20, bottom: (size !== "small") ? 40 : 0, left: 20 }}>
                    <CartesianGrid vertical={false} />
                    <YAxis type="number"
                        label={(size !== "small") ? { value: "Invoiced Amount", position: "insideLeft", angle: 0, dy: -120, dx: -25, fontSize: 14 } : ''}
                        dataKey="invoiced_amt" name="Invoiced Amount"
                        tick={{ fontSize: (size !== "small") ? 14 : 12 }}
                        tickFormatter={formatCurrency} />
                    <XAxis type="number"
                        padding={{ left: 10 }} label={(size !== "small") ? { value: "Invoiced Gross Profit Amount", dy: 20, dx: 0, fontSize: 14 } : ''}
                        dataKey="invoiced_gp_amt"
                        name="Invoiced Gross Profit Amount"
                        tick={{ fontSize: (size !== "small") ? 14 : 12 }}
                        tickFormatter={formatCurrency} />
                    <ZAxis type="string" dataKey="invoice_id" name="Invoice ID" />
                    <Scatter name="Sales History"
                        data={data_core}
                        fill="rgba(212, 157, 66, 0.6)"
                        isAnimationActive={(size === "small") ? false : true}
                        line={{ stroke: 'red', strokeWidth: (size === "small") ? 0 : 1 }}
                        lineType="fitting" />
                    <Scatter name="Outlier"
                        data={data_outliers}
                        fill="#693C72"
                        animationDuration={2000}
                        isAnimationActive={(size === "small") ? false : true}
                        shape="cross" />
                    {(size !== "small") && <Legend verticalAlign="top" />}
                    {(size !== "small") && <Tooltip cursor={{ strokeDasharray: '3 3' }} />}
                </ScatterChart>
            </ResponsiveContainer>
        </div>

    )
}

export default ScatterPlotLargeOrder

